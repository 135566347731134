import * as API from '@/api/document'

const state = {
  categories: [],
  grades: []
}

const mutations = {
  SET_CATEGORIES: (state, data) => {
    state.categories = data
  },
  SET_GRADES: (state, data) => {
    state.grades = data
  }
}

const actions = {
  GetCategories({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getCategories(data).then(response => {
        resolve(response.data)
      }).catch(reject)
    })
  },
  GetCategory({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getCategory(data).then(response => {
        resolve(response.data)
      }).catch(reject)
    })
  },
  QuickShareFile({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.quickShareFile(data).then(response => {
        resolve(response.data)
      }).catch(reject)
    })
  },
  GetGrades({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getGrades(data).then(response => {
        commit('SET_GRADES', response.data.grades)
        resolve(response)
      }).catch(reject)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
