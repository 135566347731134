const getters = {
  teachers: state => state.user.teachers,
  school: state => state.content.school,
  config: state => state.content.config,
  token: state => state.user.token,
  profile: state => state.user.profile,
  grades: state => state.document.grades,
  classes: state => state.content.classes,
  eClass: state => state.content.eClass,
  students: state => state.content.students,
  sStudents: state => state.content.sStudents,
  sLeads: state => state.content.sLeads,
  homeworks: state => state.content.homeworks,
  homework: state => state.content.homework,
  mp: state => state.content.mp,
  classReport: state => state.content.classReport,
  classSubmissions: state => state.content.classSubmissions,
  notifications: state => state.content.notifications,
  news: state => state.content.news,
  topics: state => state.discussion.topics,
  comments: state => state.discussion.comments,
  lessons: state => state.content.lessons,
  lesson: state => state.content.lesson,
  media: state => state.media,
  document: state => state.document,
  questionGroups: state => state.question.questionGroups,
  questions: state => state.question.questions,
  googleDrive: state => state.media.googleDrive,
  drive: state => state.media.drive
}

export default getters
