<script>
export default {
  inheritAttrs: false,
  model: {
    event: 'update'
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Xác nhận'
    },
    content: {
      type: String,
      default: 'Bạn có muốn thực hiện hành động này không?'
    },
    cancelButton: {
      type: String,
      default: 'Huỷ'
    }
  },
  data() {
    return {
      activeTemp: false
    }
  },
  watch: {
    active: {
      handler(val) {
        this.activeTemp = val
      },
      immediate: true
    }
  }
}
</script>

<template>
  <div
    class="base-confirm-dialog"
  >
    <md-dialog
      :md-active.sync="activeTemp"
      @md-closed="$emit('close')"
      @md-clicked-outside="$emit('close')"
    >
      <md-dialog-title>{{ title }}</md-dialog-title>
      <div>{{ content }}</div>
      <md-dialog-actions>
        <md-button class="md-danger" @click.prevent="$emit('cancel')">{{ cancelButton }}</md-button>
        <md-button class="md-primary" @click.prevent="$emit('confirm')">Xác nhận</md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>
