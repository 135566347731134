import * as API from '@/api/content'
import _ from 'lodash'
import Vue from 'vue'

const state = {
  school: null,
  config: {},
  classes: [],
  eClass: null,
  classSubmissions: [],
  students: [],
  sStudents: [],
  sLeads: [],
  homeworks: [],
  homework: null,
  teachers: [],
  lessons: [],
  lesson: null,
  mp: {
    books: [],
    lessons: [],
    sections: [],
    testCategories: [],
    tests: [],
    conversationUnits: [],
    conversations: []
  },
  classReport: {},
  notifications: [],
  news: [],
  newsPaginate: {
    total: 0,
    page: 1,
    limit: 10
  },
  meeting: null
}

const mutations = {
  SET_CONFIG: (state, data) => {
    state.config = data
  },
  SET_SCHOOL: (state, data) => {
    state.school = data
  },
  SET_CLASSES: (state, data) => {
    state.classes = data
  },
  ADD_CLASS: (state, data) => {
    state.classes.unshift(data)
  },
  UPDATE_CLASS: (state, data) => {
    const index = _.findIndex(state.classes, (eClass) => {
      return eClass._id.toString() === data._id.toString()
    })

    if (index !== -1) {
      state.classes.splice(index, 1, data)
    }
  },
  DELETE_CLASS: (state, data) => {
    const index = _.findIndex(state.classes, (eClass) => {
      return eClass._id.toString() === data._id.toString()
    })

    if (index !== -1) {
      return state.classes.splice(index, 1)
    }
  },
  SET_CLASS: (state, data) => {
    state.eClass = data
  },
  SET_HOMEWORK_SUBMISSION: (state, data) => {
    state.classSubmissions = data
  },
  SET_STUDENTS: (state, data) => {
    state.students = data
  },
  SET_SCHOOL_STUDENTS: (state, data) => {
    state.sStudents = data
  },
  SET_SCHOOL_LEADS: (state, data) => {
    state.sLeads = data
  },
  APPROVE_JOIN_CLASS: (state, data) => {
    const index = _.findIndex(state.students, student => {
      return student._id.toString() === data._id.toString()
    })

    if (index !== -1) {
      if (data._type === 'update') {
        Vue.set(state.students[index], 'status', data.status)
      } else {
        state.students.splice(index, 1)
      }
    }
  },
  UPDATE_STUDENT_INFO: (state, data) => {
    const index = _.findIndex(state.students, student => {
      return student._id.toString() === data._id.toString()
    })

    if (index !== -1) {
      state.students[index] = data
      // Vue.set(state.students[index], 'status', data.status)
    }
  },
  SET_LESSONS: (state, data) => {
    state.lessons = data
  },
  CREATE_LESSON: (state, data) => {
    state.lessons.push(data)
    state.lessons.sort((a, b) => {
      return a.sortIndex > b.sortIndex ? 1 : -1
    })
  },
  UPDATE_LESSON: (state, data) => {
    const index = _.findIndex(state.lessons, lesson => {
      return lesson._id === data._id
    })

    if (index > -1) {
      Vue.set(state.lessons, index, data)
      Vue.set(state, 'lesson', data)
      state.lessons.sort((a, b) => {
        return a.sortIndex > b.sortIndex ? 1 : -1
      })
    }
  },
  DELETE_LESSON: (state, data) => {
    const index = _.findIndex(state.lessons, lesson => {
      return lesson._id === data._id
    })

    if (index > -1) {
      state.lessons.splice(index, 1)
    }
  },
  SET_LESSON: (state, data) => {
    state.lesson = data
  },
  SET_HOMEWORKS: (state, data) => {
    state.homeworks = data
  },
  SET_HOMEWORK: (state, data) => {
    state.homework = data
  },
  SET_PUBLISH_HOMEWORK: (state, data) => {
    Vue.set(state.homework, 'publish', data)
  },
  SET_EASYCLASS_DATA: (state, data) => {
    state.easyclass = data
  },
  SET_EASYCERTIFICATE_DATA: (state, data) => {
    state.easyclass = data
  },
  ADD_HOME_WORK: (state, data) => {
    state.homeworks.unshift(data)
  },
  UPDATE_HOME_WORK: (state, data) => {
    const index = _.findIndex(state.homeworks, homework => {
      return homework._id.toString() === data._id.toString()
    })

    if (index !== -1) {
      state.homeworks.splice(index, 1, data)
    }
  },
  SET_CLASS_REPORT: (state, data) => {
    state.classReport = data
  },
  SET_NOTIFICATIONS: (state, data) => {
    state.notifications = data
  },
  SET_TEACHERS: (state, data) => {
    state.teachers = data
  },
  SET_NEWS: (state, response) => {
    state.news = response.data.news
    state.newsPaginate.total = Number(response.headers['x-pagination-total'])
    state.newsPaginate.page = Number(response.headers['x-pagination-page'])
    state.newsPaginate.limit = Number(response.headers['x-pagination-limit'])
  },
  SUBSCRIBE_STUDENT: (state, data) => {
    const index = _.findIndex(state.students, student => {
      return student._id.toString() === data.studentId.toString()
    })

    if (index !== -1) {
      Vue.set(state.students[index], 'subscribed', true)
    }
  },
  UNSUBSCRIBE_STUDENT: (state, data) => {
    const index = _.findIndex(state.students, student => {
      return student._id.toString() === data.studentId.toString()
    })

    if (index !== -1) {
      Vue.set(state.students[index], 'subscribed', false)
    }
  },
  SET_MEETING: (state, data) => {
    const index = _.findIndex(state.lessons, lesson => {
      return lesson._id.toString() === data.lessonId.toString()
    })

    if (index !== -1) {
      Vue.set(state.lessons, index, Object.assign(state.lessons[index], {
        meeting: data
      }))
    }
    state.meeting = data
  }
}

const actions = {
  getConfig({ commit }) {
    return new Promise((resolve, reject) => {
      API.getSchoolConfig().then(response => {
        const config = response.data.config
        commit('SET_CONFIG', config)
        resolve()
      }).catch(reject)
    })
  },
  updateConfig({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.updateSchoolConfig(data).then(response => {
        commit('SET_CONFIG', data)
        resolve()
      }).catch(reject)
    })
  },
  getSchool({ commit }) {
    return new Promise((resolve, reject) => {
      API.getSchool().then(response => {
        const school = response.data.school
        commit('SET_SCHOOL', school)
        resolve(school)
      }).catch(reject)
    })
  },
  getClasses({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getClasses(data).then(response => {
        commit('SET_CLASSES', response.data.classes)
        resolve(response)
      }).catch(reject)
    })
  },
  getValidContestClasses({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getValidContestClasses(data).then(response => {
        resolve(response)
      }).catch(reject)
    })
  },
  createClass({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.createClass(data).then(response => {
        commit('ADD_CLASS', response.data.class)
        resolve(response.data.class)
      }).catch(reject)
    })
  },
  updateClass({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.updateClass(data).then(response => {
        commit('UPDATE_CLASS', response.data.class)
        resolve(response.data.class)
      }).catch(reject)
    })
  },
  getClass({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getClass(data).then(response => {
        commit('SET_CLASS', response.data.eClass)
        resolve(response.data.eClass)
      }).catch(reject)
    })
  },
  getHomeworkSubmission({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getHomeworkSubmission(data).then(response => {
        commit('SET_HOMEWORK_SUBMISSION', response.data.submissions)
        resolve(response.data.submissions)
      }).catch(reject)
    })
  },
  deleteClass({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.deleteClass(data).then(response => {
        commit('DELETE_CLASS', data)
        resolve(true)
      }).catch(reject)
    })
  },
  getHomeworkComments({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getHomeworkComment(data).then(response => {
        resolve(response.data.comments)
      }).catch(reject)
    })
  },
  createHomeworkComment({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.createHomeworkComment(data).then(response => {
        resolve(response.data.comment)
      }).catch(reject)
    })
  },
  updateHomeworkComment({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.updateHomeworkComment(data).then(response => {
        resolve(response.data.comment)
      }).catch(reject)
    })
  },
  deleteHomeworkComment({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.deleteHomeworkComment(data).then(response => {
        resolve(true)
      }).catch(reject)
    })
  },
  createFileHomework({ commit }, data) {
    return new Promise((resolve, reject) => {
      data.answerKeys = data.keyTemps.map(key => key.value).filter((key) => {
        return Boolean(key)
      })
      API.createFileHomework(data).then(response => {
        resolve(response.data.homework)
      }).catch(reject)
    })
  },
  updateFileHomework({ commit }, data) {
    return new Promise((resolve, reject) => {
      data.answerKeys = data.keyTemps.map(key => key.value).filter((key) => {
        return Boolean(key)
      })
      API.updateFileHomework(data).then(response => {
        resolve(true)
      }).catch(reject)
    })
  },
  createAttachmentHomework({ commit }, data) {
    return new Promise((resolve, reject) => {
      data.answerKeys = data.keyTemps.map(key => key.value).filter((key) => {
        return Boolean(key)
      })
      API.createAttachmentHomework(data).then(response => {
        resolve(response.data.homework)
      }).catch(reject)
    })
  },
  updateAttachmentHomework({ commit }, data) {
    return new Promise((resolve, reject) => {
      data.answerKeys = data.keyTemps.map(key => key.value).filter((key) => {
        return Boolean(key)
      })
      API.updateAttachmentHomework(data).then(response => {
        resolve(true)
      }).catch(reject)
    })
  },
  createDocumentHomework({ commit }, data) {
    return new Promise((resolve, reject) => {
      data.answerKeys = data.keyTemps.map(key => key.value).filter((key) => {
        return Boolean(key)
      })
      API.createDocumentHomework(data).then(response => {
        resolve(response.data.homework)
      }).catch(reject)
    })
  },
  updateDocumentHomework({ commit }, data) {
    return new Promise((resolve, reject) => {
      data.answerKeys = data.keyTemps.map(key => key.value).filter((key) => {
        return Boolean(key)
      })
      API.updateDocumentHomework(data).then(response => {
        resolve(true)
      }).catch(reject)
    })
  },
  createMpHomework({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.createMpHomework(data).then(response => {
        commit('ADD_HOME_WORK', response.data.homework)
        resolve(response.data.homework)
      }).catch(reject)
    })
  },
  updateMpHomework({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.updateMpHomework(data).then(response => {
        commit('UPDATE_HOME_WORK', response.data.homework)
        resolve(true)
      }).catch(reject)
    })
  },
  createQuestionHomework({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.createQuestionHomework(data).then(response => {
        resolve(response.data.homework)
      }).catch(reject)
    })
  },
  updateQuestionHomework({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.updateQuestionHomework(data).then(response => {
        commit('SET_HOMEWORK', response.data.homework)
        resolve(response.data.homework)
      }).catch(reject)
    })
  },
  pushQGQuestionHomework({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.pushQGQuestionHomework(data).then(response => {
        commit('SET_HOMEWORK', response.data.homework)
        resolve(response.data.homework)
      }).catch(reject)
    })
  },
  pullQGQuestionHomework({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.pullQGQuestionHomework(data).then(response => {
        commit('SET_HOMEWORK', response.data.homework)
        resolve(response.data.homework)
      }).catch(reject)
    })
  },
  publishQuestionHomework({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.publishQuestionHomework(data).then(response => {
        commit('SET_PUBLISH_HOMEWORK', response.data.publish)
        resolve(response.data.publish)
      }).catch(reject)
    })
  },
  getStudents({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getStudents(data).then(response => {
        commit('SET_STUDENTS', response.data.students)
        resolve(response)
      }).catch(reject)
    })
  },
  getSchoolStudents({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getStudents(data).then(response => {
        commit('SET_SCHOOL_STUDENTS', response.data.students)
        resolve(response)
      }).catch(reject)
    })
  },
  getSchoolLeads({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getStudents(data).then(response => {
        commit('SET_SCHOOL_LEADS', response.data.students)
        resolve(response)
      }).catch(reject)
    })
  },
  approveJoinClass({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.approveJoinClass(data).then(response => {
        commit('APPROVE_JOIN_CLASS', data)
        resolve(true)
      }).catch(reject)
    })
  },
  updateStudentInfo({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.updateStudentInfo(data).then(response => {
        commit('UPDATE_STUDENT_INFO', data)
        resolve(true)
      }).catch(reject)
    })
  },
  getHomeworks({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getHomeworks(data).then(response => {
        commit('SET_HOMEWORKS', response.data.homeworks)
        resolve(response.data.homeworks)
      }).catch(reject)
    })
  },
  getHomework({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getHomework(data).then(response => {
        // commit('SET_HOMEWORK', response.data.homework)
        resolve(response.data.homework)
      }).catch(reject)
    })
  },
  getClassReport({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getClassReport(data).then(response => {
        commit('SET_CLASS_REPORT', response.data.report)
        resolve(true)
      }).catch(reject)
    })
  },
  getNotifications({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getNotifications(data).then(response => {
        commit('SET_NOTIFICATIONS', response.data.notifications)
        resolve(true)
      }).catch(reject)
    })
  },
  getNews({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getNews(data).then(response => {
        commit('SET_NEWS', response)
        resolve(true)
      }).catch(reject)
    })
  },
  subscribeStudent({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.subscribeStudent(data).then(response => {
        commit('SUBSCRIBE_STUDENT', data)
        resolve(true)
      }).catch(reject)
    })
  },
  unsubscribeStudent({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.unsubscribeStudent(data).then(response => {
        commit('UNSUBSCRIBE_STUDENT', data)
        resolve(true)
      }).catch(reject)
    })
  },
  setLesson({ commit }, data) {
    commit('SET_LESSON', data)
  },
  getLessons({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getLessons(data).then(response => {
        commit('SET_LESSONS', response.data.lessons)
        resolve(true)
      }).catch(reject)
    })
  },
  createLesson({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.createLesson(data).then(response => {
        commit('CREATE_LESSON', response.data.lesson)
        resolve(true)
      }).catch(reject)
    })
  },
  updateLesson({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.updateLesson(data).then(response => {
        commit('UPDATE_LESSON', response.data.lesson)
        resolve(true)
      }).catch(reject)
    })
  },
  deleteLesson({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.deleteLesson(data).then(response => {
        commit('DELETE_LESSON', data)
        resolve(true)
      }).catch(reject)
    })
  },
  score({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.score(data).then(response => {
        commit('UPDATE_HOME_WORK', response.data.homework)
        resolve(true)
      }).catch(reject)
    })
  },
  getMeeting({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getMeeting(data).then(response => {
        commit('SET_MEETING', response.data.meeting)
        resolve(response.data.meeting)
      }).catch(reject)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
