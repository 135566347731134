import serialize from '@/utils/serialize'
import request from '@/utils/request'

export function getCities(query) {
  return request({
    url: '/documents/cities' + serialize(query),
    method: 'get'
  })
}

export function getDistricts(query) {
  return request({
    url: '/documents/districts' + serialize(query),
    method: 'get'
  })
}

export function getSchools(query) {
  return request({
    url: '/documents/schools' + serialize(query),
    method: 'get'
  })
}

export function getGrades(query) {
  return request({
    url: '/documents/grades' + serialize(query),
    method: 'get'
  })
}

export function getCategories(data) {
  return request({
    url: '/documents/categories' + serialize(data),
    method: 'get'
  })
}

export function getCategory(data) {
  return request({
    url: '/documents/categories/detail' + serialize(data),
    method: 'get'
  })
}

export function quickShareFile(data) {
  return request({
    url: '/documents/categories/quick-share' + serialize(data),
    method: 'get'
  })
}

export function getTeacherPermissions(data) {
  return request({
    url: '/documents/admin/permissions' + serialize(data),
    method: 'get'
  })
}

export function setTeacherPermissions(data) {
  return request({
    url: '/documents/admin/permissions',
    method: 'put',
    data
  })
}
