import request from '@/utils/request'
import axios from 'axios'
import serialize from '@/utils/serialize'

const CancelToken = axios.CancelToken

export function getSchoolConfig() {
  return request({
    url: '/config',
    method: 'get'
  })
}

export function updateSchoolConfig(data) {
  return request({
    url: '/config',
    method: 'put',
    data
  })
}

export function getClasses(data) {
  return request({
    url: '/class' + serialize(data),
    method: 'get'
  })
}

export function getValidContestClasses(data) {
  return request({
    url: '/class/getValidContestClasses' + serialize(data),
    method: 'get'
  })
}

export function getClass(data) {
  return request({
    url: '/class/detail' + serialize(data),
    method: 'get'
  })
}

export function approveAllStudents(data) {
  return request({
    url: '/class/approve-all-students' + serialize(data),
    method: 'post'
  })
}

export function createClass(data) {
  return request({
    url: '/class',
    method: 'post',
    data
  })
}

export function updateClass(data) {
  return request({
    url: '/class' + serialize({ classId: data.classId }),
    method: 'put',
    data
  })
}

export function deleteClass(data) {
  return request({
    url: '/class' + serialize({ classId: data._id }),
    method: 'delete'
  })
}

export function getHomeworks(query) {
  return request({
    url: '/homework' + serialize(query),
    method: 'get'
  })
}

export function getHomework(query) {
  return request({
    url: '/homework/detail' + serialize(query),
    method: 'get'
  })
}

export function createHomeworkComment(data) {
  return request({
    url: '/homework/comments',
    method: 'post',
    data
  })
}

export function getHomeworkComment(data) {
  return request({
    url: '/homework/comments' + serialize({ submissionId: data.submissionId }),
    method: 'get'
  })
}

export function updateHomeworkComment(data) {
  return request({
    url: '/homework/comments',
    method: 'put',
    data
  })
}

export function deleteHomeworkComment(data) {
  return request({
    url: '/homework/comments',
    method: 'delete',
    data
  })
}

export function createHomeworkCommentUploadUrl(data) {
  if (!data.file) {
    throw new Error('E_FILE_INVALID')
  }

  const body = {
    filename: data.file.name,
    contentType: data.file.type,
    fileSize: data.file.size
  }

  return request({
    url: '/homework/comments/create-upload-url' + serialize({ submissionId: data.submissionId }),
    method: 'post',
    data: body
  })
}

export function createQuestionHomework(data) {
  return request({
    url: '/homework/es-homework' + serialize({ classId: data.classId }),
    method: 'post',
    data
  })
}

export function pushQGQuestionHomework(data) {
  return request({
    url: '/homework/es-homework-push-qgs',
    method: 'put',
    data
  })
}

export function pullQGQuestionHomework(data) {
  return request({
    url: '/homework/es-homework-pull-qgs',
    method: 'put',
    data
  })
}

export function updateQuestionHomework(data) {
  return request({
    url: '/homework/es-homework' + serialize({ classId: data.classId }),
    method: 'put',
    data
  })
}

export function publishQuestionHomework(data) {
  return request({
    url: '/homework/es-homework/publish' + serialize({ classId: data.classId }),
    method: 'put',
    data
  })
}

export function createFileHomework(data) {
  return request({
    url: '/homework/file' + serialize({ classId: data.classId }),
    method: 'post',
    data
  })
}

export function updateFileHomework(data) {
  return request({
    url: '/homework/file' + serialize({ homeworkId: data._id }),
    method: 'put',
    data
  })
}

export function createAttachmentHomework(data) {
  return request({
    url: '/homework/attachment' + serialize({ classId: data.classId }),
    method: 'post',
    data
  })
}

export function updateAttachmentHomework(data) {
  return request({
    url: '/homework/attachment' + serialize({ homeworkId: data._id }),
    method: 'put',
    data
  })
}

export function createDocumentHomework(data) {
  return request({
    url: '/homework/document' + serialize({ classId: data.classId }),
    method: 'post',
    data
  })
}

export function updateDocumentHomework(data) {
  return request({
    url: '/homework/document' + serialize({ homeworkId: data._id }),
    method: 'put',
    data
  })
}

export function createMpHomework(data) {
  return request({
    url: '/homework/mp' + serialize({ classId: data.classId }),
    method: 'post',
    data
  })
}

export function updateMpHomework(data) {
  return request({
    url: '/homework/mp',
    method: 'put',
    data
  })
}

export function uploadHomeworkAttachment(data, handleProgress, cancel) {
  const upload = new FormData()
  upload.append('file', data.file)

  return request({
    url: '/homework/upload' + serialize({ classId: data.classId, name: data.file.name, size: data.file.size }),
    method: 'put',
    timeout: false,
    data: upload,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress: handleProgress,
    cancelToken: new CancelToken(function executor(c) {
      if (cancel) {
        cancel(c)
      }
    })
  })
}

export function getHomeworkSubmission(query) {
  return request({
    url: '/homework/submission' + serialize(query),
    method: 'get'
  })
}

export function getStudents(data) {
  return request({
    url: '/student' + serialize(data),
    method: 'get'
  })
}

// export function getSchoolStudents(data) {
//   return request({
//     url: '/student/school' + serialize(data),
//     method: 'get'
//   })
// }

export function getStudent(data) {
  return request({
    url: '/student/detail' + serialize(data),
    method: 'get'
  })
}

export function approveJoinClass(data) {
  return request({
    url: '/student/approve-join-class' + serialize({
      studentId: data._id,
      classId: data.classId
    }),
    method: 'post',
    data: {
      status: data.status
    }
  })
}

export function disableStudentInClass(data) {
  return request({
    url: '/student/disable-student-in-class' + serialize({
      studentId: data._id,
      classId: data.classId
    }),
    method: 'post',
    data: {
      status: data.status
    }
  })
}

export function updateStudentInfo(data) {
  return request({
    url: '/student/update-info' + serialize({
      studentId: data._id
    }),
    method: 'post',
    data: {
      fullname: data.fullname
    }
  })
}

export function subscribeStudent(data) {
  return request({
    url: '/student/subscribe' + serialize(data),
    method: 'post'
  })
}

export function getSubscribeStudent(data) {
  return request({
    url: '/student/subscribes' + serialize(data),
    method: 'get'
  })
}

export function unsubscribeStudent(data) {
  return request({
    url: '/student/unsubscribe' + serialize(data),
    method: 'post'
  })
}

export function getRepresentativeStudents(data) {
  return request({
    url: '/student/representatives' + serialize(data),
    method: 'get'
  })
}

export function getRepresentativeTeachers(data) {
  return request({
    url: '/teacher/representatives' + serialize(data),
    method: 'get'
  })
}

export function getSchool() {
  return request({
    url: '/school/detail',
    method: 'get'
  })
}

export function getMpData(data) {
  return request({
    url: '/homework/mp-homework-data',
    method: 'post',
    data
  })
}

export function getClassReport(query) {
  return request({
    url: '/statistics/report' + serialize(query),
    method: 'get'
  })
}

export function getNotifications(query) {
  return request({
    url: '/notifications' + serialize(query),
    method: 'get'
  })
}

export function getNotificationUnseenCount(query) {
  return request({
    url: '/notifications/unseen-count' + serialize(query),
    method: 'get'
  })
}

export function getNews(query) {
  return request({
    url: '/news' + serialize(query),
    method: 'get'
  })
}

export function getNewsDetail(query) {
  return request({
    url: '/news/detail' + serialize(query),
    method: 'get'
  })
}

export function updateNotification(query) {
  return request({
    url: '/notifications/status' + serialize(query),
    method: 'put'
  })
}

export function getOverviewReport(query) {
  return request({
    url: '/statistics/overview-report' + serialize(query),
    method: 'get'
  })
}

export function getOverviewChart(query) {
  return request({
    url: '/statistics/overview-chart' + serialize(query),
    method: 'get'
  })
}

export function getSchoolAveragePoints(query) {
  return request({
    url: '/statistics/school-average-points' + serialize(query),
    method: 'get'
  })
}

export function getPricingPlans(data) {
  return request({
    url: '/plans' + serialize(data),
    method: 'get'
  })
}

export function registerPricingPlans(data) {
  return request({
    url: '/plans/register',
    method: 'post',
    data
  })
}

export function getECPointHistories(data) {
  return request({
    url: '/student/ec-point-histories' + serialize(data),
    method: 'get'
  })
}

export function getECStatistics(data) {
  return request({
    url: '/student/ec-statistics' + serialize(data),
    method: 'get'
  })
}

export function getECMissionStatistic(data) {
  return request({
    url: '/student/ec-mission-statistic' + serialize(data),
    method: 'get'
  })
}

export function getStudentHomeworks(data) {
  return request({
    url: '/student/homeworks' + serialize(data),
    method: 'get'
  })
}

export function getLessons(data) {
  return request({
    url: '/lessons' + serialize(data),
    method: 'get'
  })
}

export function createLesson(data) {
  return request({
    url: '/lessons',
    method: 'post',
    data
  })
}

export function updateLesson(data) {
  return request({
    url: '/lessons',
    method: 'put',
    data
  })
}

export function deleteLesson(data) {
  return request({
    url: '/lessons',
    method: 'delete',
    data
  })
}

export function score(data) {
  return request({
    url: '/homework/score' + serialize(data),
    method: 'post',
    data
  })
}

export function getMeeting(data) {
  return request({
    url: '/meetings',
    method: 'post',
    data
  })
}
