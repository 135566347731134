import Vue from 'vue'
import { IonicVueRouter } from '@ionic/vue'
import AppLayout from '@/views/layout/App.vue'

Vue.use(IonicVueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/Register.vue')
  },
  {
    path: '/',
    name: 'Home',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/tin-tuc/:newsUrl',
    name: 'NewsDetail',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/NewsDetail.vue')
  },
  {
    path: '/truong-hoc/:tab',
    name: 'School',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/School.vue')
  },
  {
    path: '/lop-hoc',
    name: 'Class',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/Class.vue')
  },
  {
    path: '/lop-hoc/:classUid',
    name: 'ClassDetail',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/ClassDetail.vue')
  },
  {
    path: '/lop-hoc/:classUid/btvn-tai-len',
    name: 'ClassFileHomework',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/ClassFileHomework.vue')
  },
  {
    path: '/lop-hoc/:classUid/mp/',
    name: 'mp',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/ClassHomeworkMarketPlace.vue')
  },
  {
    path: '/lop-hoc/:classUid/btvn-speaking',
    name: 'ClassAttachmentHomework',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/ClassAttachmentHomework.vue')
  },
  {
    path: '/lop-hoc/:classUid/btvn-thu-vien',
    name: 'ClassDocumentHomework',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/ClassDocumentHomework.vue')
  },
  {
    path: '/lop-hoc/:classUid/btvn-cau-hoi',
    name: 'ClassQuestionHomework',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/ClassQuestionHomework.vue')
  },
  {
    path: '/lop-hoc/:classUid/:homeworkUid',
    name: 'ClassHomeWorkDetail',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/ClassHomeWorkDetail.vue')
  },
  {
    path: '/hoc-sinh',
    name: 'Students',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/Students.vue')
  },
  {
    path: '/hoc-sinh/:studentId',
    name: 'StudentDetail',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/StudentDetail.vue')
  },
  {
    path: '/tai-lieu',
    name: 'Document',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/Document.vue')
  },
  {
    path: '/tai-lieu/phan-quyen',
    name: 'Document-Permissions',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/DocumentPermission.vue')
  },
  {
    path: '/su-kien',
    name: 'Event',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/Event.vue')
  },
  {
    path: '/su-kien/:eventCode',
    name: 'EventContest',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/Contest.vue')
  },
  {
    path: '/su-kien/bxh/:eventCode',
    name: 'EventRanking',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/EventRanking.vue')
  },
  {
    path: '/su-kien/:eventCode/cuoc-thi/:contestCode',
    name: 'EventContestQuestion',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/ContestQuestion.vue')
  },
  {
    path: '/pricing',
    name: 'PricingPlan',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/PricingPlan.vue')
  },
  {
    path: '/huong-dan',
    name: 'Instruction',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/Instruction.vue')
  },
  {
    path: '/lien-he',
    name: 'Contact',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/Contact.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    meta: {
      layout: AppLayout
    },
    component: () => import('@/views/Profile.vue')
  }
]

export default new IonicVueRouter({
  mode: Vue.prototype.$myDevice === 'isMobileApp' ? 'hash' : 'history',
  base: process.env.BASE_URL,
  routes: routes
})
