<script>
export default {
  inheritAttrs: false,
  model: {
    event: 'update'
  },
  props: {
    disabled: {
      type: Boolean,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    icon: {
      type: String
    },
    styleIcon: {
      type: Object
    },
    styleButton: {
      type: Object
    },
    menuTrigger: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<template>
  <div class="base-button">
    <button
      :disabled="disabled"
      type="button"
      class="md-button md-raised"
      :style="'color:' + (styleButton && styleButton.color ? styleButton.color : '#fff') + ';background:' + (styleButton && styleButton.background ? styleButton.background : '#15A5FF') + ';border:' + (styleButton && styleButton.border ? styleButton.border : '') + (disabled ? ';opacity: 0.5; cursor: not-allowed' : '')"
      :md-menu-trigger="menuTrigger"
      @click="$emit('click')"
    >
      <div class="md-button-content">
        <i v-if="icon" class="md-icon md-icon-font" :style="'color:' + (styleIcon && styleIcon.color ? styleIcon.color : '#fff')">{{icon}}</i>
        {{label}}
      </div>
    </button>
  </div>
</template>

<style lang="scss">
.base-button {
  font-size: 28px;
  line-height: 29px;
  border-radius: 8px;
  .md-button {
    border-radius: 5px;
    box-shadow: 0 0 black !important;
    .md-button-content {
      padding: 5px 20px;
      text-transform: none;
      font-size: 18px;
      line-height: 18px;
    }
  }
}
@media only screen and (max-width: 320px) {

}
@media only screen and (max-width: 480px) {

}
@media only screen and (max-width: 600px) {

}
@media only screen and (max-width: 768px) {

}
@media only screen and (max-width: 900px) {

}
@media only screen and (max-width: 1024px) {

}
</style>
