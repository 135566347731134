import request from '@/utils/request'
import serialize from '@/utils/serialize'

export function getQuestionGroups(data) {
  return request({
    url: '/homework/question-groups' + serialize(data.query),
    method: 'get'
  })
}

export function createQuestionGroup(data) {
  return request({
    url: '/homework/question-groups' + serialize(data.query),
    method: 'post',
    data: data.body
  })
}

export function updateQuestionGroup(data) {
  return request({
    url: `/homework/question-groups/${data.params.questionGroupId}`,
    method: 'put',
    data: data.body
  })
}

export function deleteQuestionGroup(data) {
  return request({
    url: `/homework/question-groups/${data.params.questionGroupId}` + serialize(data.query),
    method: 'delete'
  })
}

export function getQuestions(data) {
  return request({
    url: `/homework/question-groups/${data.params.questionGroupId}/questions` + serialize(data.query),
    method: 'get'
  })
}

export function createQuestion(data) {
  return request({
    url: `/homework/question-groups/${data.params.questionGroupId}/questions` + serialize(data.query),
    method: 'post',
    data: data.body
  })
}

export function updateQuestion(data) {
  return request({
    url: `/homework/question-groups/${data.params.questionGroupId}/questions/${data.params.questionId}`,
    method: 'put',
    data: data.body
  })
}

export function deleteQuestion(data) {
  return request({
    url: `/homework/question-groups/${data.params.questionGroupId}/questions/${data.params.questionId}` + serialize(data.query),
    method: 'delete'
  })
}
