import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/ionic.js'
import './registerServiceWorker'
import store from './store'
import eventBus from './utils/event-bus'
import 'normalize.css/normalize.css'
import VueMaterial from 'vue-material'
import VueI18n from 'vue-i18n'
import i18nVi from './lang/vi.json'
import i18nEn from './lang/en.json'
import './icons'
import './permission'
import * as filters from './filters'
import '@/styles/element-ui-variables.scss'
import vi from 'element-ui/lib/locale/lang/vi'
import locale from 'element-ui/lib/locale'
import {
  Select,
  Option,
  OptionGroup,
  Notification,
  Loading,
  Upload,
  DatePicker,
  Progress,
  Tree,
  Button,
  Checkbox,
  Card,
  Dialog,
  Table,
  TableColumn,
  Radio,
  Input,
  Tag,
  RadioButton
} from 'element-ui'

import '@/styles/index.scss'
import '@/components/_globals'

locale.use(vi)

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.config.productionTip = false

Vue.use(Select)
Vue.use(Option)
Vue.use(OptionGroup)
Vue.use(Upload)
Vue.use(Card)
Vue.use(DatePicker)
Vue.use(Progress)
Vue.use(Tree)
Vue.use(Checkbox)
Vue.use(Button)
Vue.use(Input)
Vue.use(Radio)
Vue.use(Dialog)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Tag)
Vue.use(Loading.directive)
Vue.use(RadioButton)
Vue.prototype.$eventBus = eventBus
Vue.prototype.$notify = Notification
Vue.config.productionTip = false
Vue.config.ignoredElements = [/^ion-/]
Vue.use(VueMaterial)

const isMobile = {
  Android: function() {
    return navigator.userAgent.match(/Android/i)
  },
  BlackBerry: function() {
    return navigator.userAgent.match(/BlackBerry/i)
  },
  iOS: function() {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i)
  },
  iPad: function() {
    return navigator.userAgent.match(/iPad/i)
  },
  Opera: function() {
    return navigator.userAgent.match(/Opera Mini/i)
  },
  Windows: function() {
    return navigator.userAgent.match(/IEMobile/i)
  },
  any: function() {
    return (
      isMobile.Android() ||
      isMobile.BlackBerry() ||
      isMobile.iOS() ||
      isMobile.Opera() ||
      isMobile.Windows()
    )
  }
}
Vue.prototype.$isMobile = isMobile

if (
  isMobile.Android() ||
  isMobile.BlackBerry() ||
  isMobile.iOS() ||
  isMobile.Opera() ||
  isMobile.Windows()
) {
  Vue.prototype.$isMobileDevice = true
} else {
  Vue.prototype.$isMobileDevice = false
}
if (document.location.protocol === 'file:') {
  Vue.prototype.$myDevice = 'isMobileApp'
  // loadjscssfile("bower_components/framework7/css/framework7.material.colors.css", "css");
} else {
  Vue.prototype.$myDevice = 'notMobileApp'
}
if (isMobile.iOS() && document.location.protocol === 'file:') {
  Vue.prototype.$iPrefix = ''
} else {
  Vue.prototype.$iPrefix = ''
}

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'vi',
  availableLocales: ['vi', 'en'],
  fallbackLocale: 'vi',
  messages: {
    vi: i18nVi,
    en: i18nEn
  },
  sync: true
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
